<template>
  <main class="home_view">
    <section class="container is-widescreen pt-5 pb-5">
      <div class="columns">
        <div class="column is-5">
          <b-image
            :src="require('@/assets/home_image.svg')"
            alt="@edwinarizacom"
        ></b-image>
        </div>
        <div class="column is-7">
          <div class="content">
            <h1 class="is-bolder has-text-centered" v-html="mainTitle[$props.lang]"></h1>
            <section class="services" v-for="option in options" :key="option.id">
              <h2 v-html="option.name[$props.lang]"></h2>
              <a class="button is-info is-rounded is-light mr-2 mt-2" v-for="service in option.services" :key="'service-'+option.id+'-'+service.id"  :href="service.link[$props.lang]">{{service.name[$props.lang]}}</a>
            </section>
          </div>
        </div>
      </div>
    </section>
    <LangButtonComponent :lang="$props.lang" />
  </main>
</template>
<script>

import LangButtonComponent from "@/../../edwinariza.ui/components/Buttons/LangButtonComponent.vue";
export default {
  name: 'HomeView',
  components:{
    LangButtonComponent
  },
  props: {
    lang: {
      type: String,
      default: 'EN'
    },
    title: {
      type: String,
      default: 'Edwin Ariza - IT Expert'
    },
    description: {
      type: String,
      default: 'Innovation and technology consultant.'
    }
  },
  data(){
    return {
      mainTitle: { ES: '¿En que te puedo ayudar?', EN: 'How can I help you?' },
      options: [
        { id: 1, name: {ES: 'Proyectos', EN: 'Projects'},
          services: [
            {
              id: 1,
              name: { ES: 'Formulación de Proyectos', EN: 'Project Formulation'},
              description: 'Formula proyectos de innovación y tecnología para entidades de apoyo.',
              link: { EN: '/project_formulator', ES: '/es/formulador_de_proyectos' }
            },
            {
              id: 2,
              name: { ES: 'Gestión de Proyectos', EN: 'Project Management'},
              description: 'Gestiona proyectos} de innovación y tecnología para entidades de apoyo.',
              link: { ES: '/es/gerente_de_proyectos', EN: '/project_manager'}
            },
            //{ id: 3, name: 'Auditoría de Proyectos', description: 'Audita proyectos de innovación y tecnología para entidades de apoyo.' },
            /**{
              id: 4,
              name: { ES:'Evaluación de Proyectos', EN: 'Project Evaluation' },
              description: 'Evalua proyectos de innovación y tecnología para entidades de apoyo.',
              link: { ES: '/es/evaluacion_de_proyectos', EN: '/project_evaluation'}
            },**/
          ]
        },
        { id: 2, name: { ES:'Auditorías', EN: 'Audits' },
          services: [
            {
              id: 1,
              name: { ES: 'Auditoría ISO 27001:2022', EN: 'ISO 27001:2022 Audit' },
              description: 'Auditoría profesional ISO 27001:2022 para evaluar el cumplimiento de su sistema de gestión de seguridad de la información. Identificamos brechas, riesgos y oportunidades de mejora para fortalecer su protección de datos y preparación para certificación.',
              link: { ES: '/es/auditoria_iso_27001', EN: '/iso_27001_audit' }
            },
            {
              id: 2,
              name: { ES: 'Auditoría de Software', EN: 'Software Audit' },
              description: 'Evaluamos su software en profundidad para identificar fallas, riesgos y oportunidades de mejora en calidad, seguridad, arquitectura y cumplimiento. Obtenga un diagnóstico claro y recomendaciones expertas para optimizar su sistema y reducir vulnerabilidades.',
              link: { ES: '/es/auditoria_de_software', EN: '/audit_software' }
            }
          ]
        },
        {
          id: 3, name: { ES: 'Software', EN: 'Software'},
          services: [
            {
              id: 1,
              name: {ES: 'Aplicaciones Web ', EN: 'Web Applications'},
              description: 'Desarrollamos software a la medida para satisfacer las necesidades específicas de su negocio. Desde aplicaciones web hasta soluciones móviles, nuestro equipo de expertos crea productos innovadores y escalables que impulsan su éxito.',
              link: {ES: '/es/software_web', EN: '/software_web'}
            },
            {
              id: 2,
              name: { ES: 'Aplicaciones Móviles', EN: 'Mobile Applications' },
              description: 'Creamos aplicaciones móviles personalizadas para iOS y Android, optimizando la experiencia del usuario y maximizando el rendimiento. Nuestro enfoque ágil garantiza un desarrollo rápido y eficiente, adaptado a sus necesidades.',
              link: { ES:'/es/software_mobile', EN: '/software_mobile' }
            },
            {
              id: 3,
              name: { ES:'Análisis de Requerimientos', EN: 'Requirements analysis'},
              description: 'Evalúa proyectos de innovación y tecnología para entidades de apoyo.',
              link: { ES:'/es/analisis_de_requerimientos', EN: '/project_requirements'}
            }
          ]
        }
      ]
    }
  },
  beforeMount() {
    this.$emit('updateMeta', this.title, this.description, this.lang);
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/main.scss";
  .home_view{
    background: #00d4ff;
    .content{
      border-left: 10px solid #6f96f3;
      padding-left: 1rem;
      padding-top:3rem;
      padding-bottom:3rem;
      h1{
        font-size: 3rem;
        line-height: 4.2rem;
        font-weight: bolder;
        color: #222222;
      }
      .services{
        margin-top: 2rem;
        padding-bottom: 1rem;
        margin-left: 2rem;
        border-bottom: 3px dashed #fefefe;
        h2{
          font-size: 1.5rem;
          color: #2f2f2f;
        }
      }
    }

  }
</style>